
<template>
    <div class="container">
        <div class="title">
            <span class="title-left">
                <img src="../../../assets/img/company/permaicon.png" alt="" style="margin-right: 8px" />
                Performance
            </span>
        </div>
        <div class="footer" v-if="cardlist.length > 0">
            <div class="ecahrts-header">
                <span class="title-left">
                    <img src="../../../assets/img/company/permaicon.png" alt="" style="margin-right: 8px" />
                    Performance
                </span>

                <div class="head_loging computer_right_cont_box">
                    <el-switch active-color="#13ce66" v-model="loginStatus"></el-switch>
                </div>
            </div>
            <div v-show="loginStatus" class="ecahrtstu">
                <div class="table">
                    <div class="table_box" style="position: relative">
                        <el-card class="box-card">
                            <div v-for="(item, index) in cardlist" :key="index" class="text item">
                                <div class="computer_right_cont_box" :style="[bc, { backgroundColor: item.class }]"><img style="height: 20px; width: 20px" :src="item.icon" alt="" /></div>

                                <div class="card-text">
                                    <span class="text-head">{{ item.name }}</span>
                                    <h2 class="text-foot">{{ item.text }}</h2>
                                </div>
                            </div>
                        </el-card>
                        <!-- <button @click="update()" class="btn_blue">High quality information</button> -->
                    </div>
                    <div class="phone_page_bg">
                        <div class="phone_flex">
                            <el-button class="btn_export" @click="More()">More</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="computer_right_cont_box" v-if="parentTablelist.length > 0">
            <div class="title" style="margin-top: 20px">
                <span class="title-left">
                    <img src="../../../assets/img/company/permaicon.png" style="margin-right: 8px" />
                    Related Performance
                </span>
            </div>
            <div class="infinite-list-wrapper" v-infinite-scroll="infiniteScroll" style="overflow: auto" :infinite-scroll-disabled="false" :infinite-scroll-distance="5">
                <!-- <div v-infinite-scroll="load" infinite-scroll-disabled="disabled"> -->
                <div class="footer" v-for="(item, index) in parentTablelist" :key="index" v-loading="item.loading2">
                    <div class="ecahrts-header">
                        <span class="title-left">
                            <img src="../../../assets/img/company/permaicon.png" alt="" style="margin-right: 8px" />
                            <span v-show="!showTranslate" style="color: #1290c9; cursor: pointer; margin-right: 5px; width: 580px" class="line_1" @click="setid(item)">{{ item.nameEn }}</span>
                            <span v-show="showTranslate" style="color: #1290c9; cursor: pointer; margin-right: 5px; width: 580px" class="line_1" @click="setid(item)">{{ item.nameCn }}</span>

                            Performance
                        </span>
                        <span style="display: flex; justify-content: flex-start; width: 280px">relations:{{ item.typeEn }}</span>

                        <div class="head_loging">
                            <el-switch active-color="#13ce66" :value="loginStatus1[index]" @change="loginPop(index, item.aaaid, item.nameEn)"></el-switch>
                        </div>
                    </div>
                    <div v-show="loginStatus1[index]" class="ecahrtstu">
                        <div class="table">
                            <div class="table_box" style="position: relative">
                                <el-card class="box-card">
                                    <div v-for="(item, index) in item.cardlist2" :key="index" class="text item">
                                        <div :style="[bc, { backgroundColor: item.class }]"><img style="height: 20px; width: 20px" :src="item.icon" alt="" /></div>

                                        <div class="card-text">
                                            <span class="text-head">{{ item.name }}</span>
                                            <h2 class="text-foot">{{ item.text }}</h2>
                                        </div>
                                    </div>
                                </el-card>
                                <!-- <button @click="update()" class="btn_blue">High quality information</button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="back-to-top" v-show="showBackToTop" @click="scrollToTop">
            <button class="el-icon-top gaodu"></button>
        </div>

        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normalsuo" :append-to-body="true">
            <div>
                <!-- <div class="dialog_detail_title">Pay attention to</div> -->
                <div class="detail_cont">
                    <div class="detail_cont">
                        <div class="detail_cont_text">• This is a paid feature, open it to view all the available online information about this company. This will cost 1 unit.</div>
                    </div>
                </div>
            </div>
            <div class="flex_center_between_box">
                <el-button class="btn_export" id="ok" @click="onSubmitkoudian()">OK</el-button>
                <el-button class="btn_export" @click="onSubmitquxiao()">Buy credits</el-button>
            </div>
        </el-dialog>
        <Phonetocsi class="phone_page_bg" :suoClose="moreclose" @closeCloseDialog="closmoreDialog($event)"></Phonetocsi>
    </div>
</template>
<script>
import crypto from '@/utils/crypto';
import { getrelatedCompanylist, getCompanyDetailMenuList, ordersAdd, getPerformance } from '../../../api/companyApi';
import { mapGetters, mapActions } from 'vuex';
import { mixins1 } from '@/mixins/index';
import Phonetocsi from '@/components/phonetocsi.vue';
export default {
    mixins: [mixins1],
    components: { Phonetocsi },
    data() {
        return {
            loginStatus: true, //登录状态
            pageChild: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            bc: {
                height: '40px',
                width: '40px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            parentTablelist: [],
            companyList: [],
            noMore: false, // 控制滚动禁用
            routeLoad: false, // 控制滚动禁用
            showBackToTop: false,
            loginStatus1: [],

            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            cardlist2: [
                { icon: require('@/assets/img/company/rev.png'), name: 'Revenue', text: '', class: '#1290C9FF' },
                { icon: require('@/assets/img/company/pro.png'), name: 'Profit', text: '', class: '#55B419FF' },
                { icon: require('@/assets/img/company/gro.png'), name: 'Growth', text: '', class: '#022955FF' },
                { icon: require('@/assets/img/company/LIqui.png'), name: 'Liquidity', text: '', class: '#F56B6BFF' },
                { icon: require('@/assets/img/company/finan.png'), name: 'Financial Structure', text: '', class: '#01DAC3FF' },
                { icon: require('@/assets/img/company/crea.png'), name: 'Credit Opinion', text: '', class: '#6668D1FF' },
                { icon: require('@/assets/img/company/risk.png'), name: 'Risk Indicator', text: '', class: '#FF7600FF' },
                { icon: require('@/assets/img/company/crea.png'), name: 'Solvency index (SI)', text: '', class: '#1290C9FF' },
            ],
            // 提示处理
            newDialog: false,
            indexclose: '',
            Obj: {
                id: '',
                name: '',
                index: '',
            },
            params: {},
            suoclose: false,
            moreclose: false,
        };
    },
    computed: {
        ...mapGetters({
            cardlist: 'performance/cardlist1',
        }),
        showTranslate: {
            get() {
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return JSON.parse(this.$parent.powerToPage);
            },
            set() {},
        },
    },
    watch: {
        $route: {
            handler(val, oldval) {
                console.log(val.path.replace('/', '')); //新路由信息
                this.Indexy = val.path.replace('/', ''); //当前活动路由
            },
            // 深度观察监听
            deep: true,
        },
    },
    mounted() {
        this.getlist();
        this.getrelateList();
        this.loginStatus1 = new Array(this.parentTablelist.length).fill(false); // 初始化展开状态数组
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        // 移除页面滚动事件的监听
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        ...mapActions({
            getcardlist: 'performance/getcardlist',
        }),
        More() {
            this.moreclose = true;
        },
        closmoreDialog(value) {
            this.moreclose = value;
        },
        update() {
            console.log(`this.$parent`, this.$parent);
            this.$parent.highquality = !this.$parent.highquality;
            let objt = [
                {
                    icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAe1JREFUOE+t1E2IzWEUx/HPIZEFCyFigShsyEI0QhJWUpKdRFFW3hZmo4QF5S1vJUWsDGWFYmeIlBSJFCUWzAyZoRT16Og/9XdNc2firJ77v+d8z8vze06oWSllOJajBXMwBeMwEoFv6MAbPEE7bkfE915MOimljMEebMboepIBnLtxHgcjojNKKbNwC5MHENyfy3usTOB9LPhHWG/4wwR2Ilv+H9adwMPY9T9oOJ7AEdiLnRg2CHDBO+TsevAoOQlcFxFXSilTsQVrkee+LAOv4jruRsTnRqcEprb24WRE5DllNBFzKx2OrXT4IeXRF6QOTeAdLKvKvpmZ8awSb1dEfB3EGCQw9XcOK/oIfIzFjdBSyhFsx8yIeNFYYSq8tZSyCBsq8CT8wOyIeNWYqBkwAx/gRL6YiOgppUzA+IjI9/qXNQNeqCrLwIRnCy9xNCLyFf22UsqxakHkz+wmL+0Sem+6PSLacobZ3ibswKhaOSmnthrwJ4b2c0GnI2JbAm9gfeW4ulpf83AxIg7VgNNqFbZiYzXv15XPl4joSGAXMvsZXIuIp81k0myGW3EKQyrQp0qHb3E2Iu4N6pargS/FfixsCM6Bt0TE8/r3UsoaLMGBiPj4hw4bHKdjFeZjRrV0cwnsjojLzUaR//8Cdd/JfwuYaqUAAAAASUVORK5CYII=',
                    name: 'Revenue',
                    text: 'CNY 47,359 K',
                    class: '#1290C9FF',
                },
                {
                    icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAe9JREFUOE+tlT1oFVEQhb/jDzZWgoUWNlZiqSAWWqQSDP4UoohRmxgQJRBQEpAUaQwKIgqK2qgJIZ2K2omNgYDYayOIiDZiIXYmGTlh3mazb+9DxGkeb3fuuXPOnJkVLRER64EjwGFgN7A1074C74BnwFNJv5vH1XwQEUeB68Am4CXwBjCQw8D7gIPAD+CSpCd1jAowItYAk8BF4CpwQ9KvAoONwAgwBtwGRiUtObcOeA04Y6qS5v0yInzwAbA2gReBwc5FEbHX1IFHki5XgElzBujrgHUqi4gp4FT+n5Y0UK86QV8DJ01f2YD3wGNJEy2abgfO5/M7kj625IwDp4EdBjwG3AO29dDsvuWRNNhD08/AkAGnLVeTSo2ym/U99d4saaEAamlkwA/ZUVfRFRGxE7CV3NVDkuYKeeecY8CfKeiLQuIocMWXAm8llfL6gZm/AbTgWwBXMCXpbOHiCrBIOSI2AF9yKh6m6XdJ+tbS6YpysSkRcQKwhvuBPcDNNLFttirSr8tNKdomImaB4zkNn4ALtpgk/1aRE1XZxpul1dgRcQB4DqwDfOCut42kVw3AFWPnzNoWpdEbTqpOHZZ0q+fo1QzctRxq7zxJHpWhFrDu5ZBVFtdXzrsBlxdqatZ7fdWq+T8LtkHlnz8BfwCi7AyWLUAy4wAAAABJRU5ErkJggg==',
                    name: 'Profit',
                    text: 'CNY 5,940 K',
                    class: '#55B419FF',
                },
                {
                    icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAc5JREFUOE/l1D2oD2AUx/HP6V6vF6WUwahksSth8ZKEiUgymwgDicHLTUkpo8EoL8lryiTFwMbCpCxIyftrHJ1/z//2d92/wbV5xvP0fM9zfud3TvjLk5lLcQNTehAZf8nrPMvM5bjcCx0XsEFPYHv3Y+MCZuZeHMF7TMSkMYGZORMHcCgiXo2WJTMHcBLb8AyrMQcXfgNm5lxcw3w8xqqIeNKFZmY14QzW4VHd4zXeYeUvwMxcjIuYhYdYgBdYExH3M7PiV7EQd7AWX3ATyyLi0wgwM7fgFCZgf0QMZ+YeDOMjdmEn5rWkm/GjJViBqR1gZsc6B7EPn7E1Is73lLgJp0vwFivtdkTEj8w8h/UtPgI8iw14XrpExL3MnIHj2B0Rb5qJS4qjEXGsJ9lbTB8NTDxoOj1t3rqN0vNuRCxqsRkRUYCRk5ljAq9jY0RUl7oTUKV3SxyMiO89dyXP4Yj42g84UA/KDiVq+81vwOa9moiSYlJfYANMxpWIqG7VjI4FvIUlqCb2B2ZmjcylZuCOjfoAP5Q1Wul/BNa2KIOKiH8C/IbB/wv4BjUZHyNiqDWlVlatsLLRUERNaL5sS6McMK1ZraZrdlsQ0yPi20+bNTZPomV/5QAAAABJRU5ErkJggg==',
                    name: 'Growth',
                    text: '0.41 %',
                    class: '#022955FF',
                },
                {
                    icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAfFJREFUOE+t1EuojmEUBeBnO6RcikLEQEpKcptTJsiAlKKEgZggKSMmkuRWJlIGDNwGCjGQWyakFIVSpBMZGAgpcku2Nt/R7zj/OTnZk2/wrnd9a6+13x36qMycg+URsbEvbJ1HO1BmjsE+LMIw3MHWiLjXG3FvhBcxC6eb7yBMwcSI+NqOtDfCVziOC9UyDmI3tkVEZ38Ir2M2TqDjf3g4AUewAO9xCgci4nm/POy6lJkVyh6MQvk4IyJe/lPLmdmBo42qz42H+1HqNkTE4X8iLHBmPkAp2YtlTThXsSoiTvaHsLw7j28VCobgJuZHRKnusX6PTWZWCEsxFk9wFiOwFnOxIyJuZeb4Gp3G0xd4jIfojIi30QB2YjUGtvz2KRaj1EyPiIuZuQLl38g2As8V4SNMbQN4g/URcS4zJ2N74+fQNvhrRVigXX08/GrpFs40Xl7qAX8bC4uwlkB5MbgF9B0Derj0DKW0gmqtG1gSER9+hpKZ9WZXtSDqYhk/CTMxDl+aoN41C6MLXuGtjIg6/7W+MrPe7N1u6+wjjuEKalFU4vOwpaWbQ9gcEdXRz2odm8vNeJSC0d0S7959NlunnuQf1UpY8/W6pGfmcKxpUq25bK1PWBcRtSz+qrb7sLGixqMGu/yd1tiyKSLut5uKHxFqo4Azz4B3AAAAAElFTkSuQmCC',
                    name: 'Liquidity',
                    text: 'Caution',
                    class: '#F56B6BFF',
                },
                {
                    icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAe5JREFUOE/d1E+I1lUUxvHPQ+AQKIaSLcVFIK0UMcNahBvTyH+gUBYZ2Ngy0RDUXSkoSpTiQhfTSOCfhVAQYWAQhOBiNgkqhQshiJJyYYja4sSFO/E6jTMhuOks3+ec731+9zzvjf9QVfUc9iZ5Y7r2TNVQVc/gE6zCTFzFriRfPWxuOuBZLMMBrMcNNJcLkvw6GXQ64DWMYV/7ZLyH7TiV5MdHAR7qgG9wN0lzOWX9y2FVDWFGkttV9QS2YSsWo7nan2S0qmbhryR3B094AFhV+7EDPyRZOt7Yt3wUP+NNrO5X8HxbWpIPxnv/AVbVpnY3eB+nk9ysqus4g887YDPu4CCOoM18ii1JWo9B4LG20SRLBpw1V6/jMF7psRluDpN83fqq6iKuJGnX8gBwBM8meWkA2LLXIvMansZlHE1ycqDnW/ySpLmfGjgw9CEWJtk4ccVV9UjAcxhK8ur/AFhVi3okXsSTuNDDO9a1PXi53/d3A1pLw26swD18j49SVT/hNtr6W73QNppk/iRaeyjmda09FL/hUp9bjlkNWBhOcqLn6m181v5+uP8QrcXpzx7o0T73Lo4/FuAfOI8WjVZrsQaz8fsErf2+Dk/hFr7EF31uA1Y2h2/hY8ztQjtgZ5KRabR30J63OX2uHb79b/LwCGYmGiPUAAAAAElFTkSuQmCC',
                    name: 'Financial Structure',
                    text: 'Strong',
                    class: '#01DAC3FF',
                },
                {
                    icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAe9JREFUOE+tlMurjVEYxn+PW3KLwh9gYqIkYqIwIaUYOAM5Uc4IEykmFIURpVMnSiIK5XIMTJgYKDJQBihlZCAjM5eQ8+jZraXv7G3b3ymrvlrfWu/7W+9d/IdlewtwEFipyrO9HtgOLBnwxpikFw29EeASMA141AHaHisv5PdHjv4B3SXpftHbDVwDphf5YdnOC5eB28BRSe/bRMH2TuAWMKPIn5d0OMCXwBxghaSfLWHbgHvArCJ/UdKB7AP8DlyRtL8lbAPwEJhd5K8CI5I6YQowm3OSjrQEvgWWF9m4PCxpoupOAtreDBwqSclD9bsu6a7ttcDzGALcBPaW7M6U9KXHQturgD1FKEr1G5f02PYNYCFwRtKzkukTwElgvqTPU3LZdiyZlDjb/YG2FwBrimXdIQ3oqaRfzYtBwGT6Qp/kBLha0qs+wHmJY4/LtlOoiV13t0w0s9lovery34FtSqfLwiQk0F6g7aFy2SyZ7FNn3WdnJd2xXYFzJX2NywnyaPrQ9jJgR6NcMkGa5ZN9PUspvbF9Cjie9pX0LcAPwGtJmWlTXrbHgU2SFtXCzuhKYw9JSsO3Xra3Ag+AdNK+Clxc2inuPgHeDZiHHT0g8huBeLhO0sd6kQG7FDjdmNh/Jnkfc5OgT8W6YxUW2d/z0P8Aqj1uxQAAAABJRU5ErkJggg==',
                    name: 'Credit Opinion',
                    text: 'BBB',
                    class: '#6668D1FF',
                },
                {
                    icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAlZJREFUOE+FlEuIzmEUxn/PjMZ1YSVKikYhk42FnYVLUS4ZFhYiJOOau1IuKxETC3IJGzEIxQK5JBtZzAJNJJeSS8JKIbej5+v9vv7ff17j1Fff/33Pec57znOeIzIWEU3AXGA2MB4YAgh4B9wHOoAbkn6Uw+1UZxExD2gHhuaSFc5eAislXS/61QFGxEFgTXJ4CBwH7gAOto0AJgHLgJZ0tkfS1ipoDTAidgI7gO/AOuCYpD//aInj2oB9QF9gi6S99q0ARkQrcCGBTZd09z/lVq4jwq91exzztgKYCHiRetYm6UimryZmUDrvlPSh6hMRjZJ+10qOiPnAGeAxME5SFAEjoj/wBhgIfAOGSfqUSbrCU+AXngfM7CpJhzKOJuBoOj8paUnGZzVgQjsMaAaHA2MkPck4Pyowuhnwd5ckv9p9nAFcAnoBTw3oMvoA/ST5f80iYiJQJugjMFrS5wQ4yrFAp1tSBHTjvxZfGRGnAE9AA+Be2hZIOl1KPAD44l+xZMtomiQPcp1FxGLgBHBT0tTM/dhE6nMDngPmAAslme1uFhH3kqZbJHnEygnXAgeAswY0ww2SDJwDawaeAdsk7c6AWRxd7qunpazlpvIGiYhFqYJWST8zgNa+R+Y10FzU8kjgGrBc0q3cazNgU4CrQG9glqQrRcDtwK6k503A4R6WQ6NXF+CFYLB2SRucsFzy/rRpqn3x+roNvEq+FsBkYKmF4LlO5a6vSja3YGcmxhzck1lhGyVdLjp1A/RlRFhGHiXLagIwOL3wPfAg9e2ipF/ljH8BTnL0iW9hdakAAAAASUVORK5CYII=',
                    name: 'Risk Indicator',
                    text: 'Low Risk',
                    class: '#FF7600FF',
                },
                {
                    icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAe9JREFUOE+tlMurjVEYxn+PW3KLwh9gYqIkYqIwIaUYOAM5Uc4IEykmFIURpVMnSiIK5XIMTJgYKDJQBihlZCAjM5eQ8+jZraXv7G3b3ymrvlrfWu/7W+9d/IdlewtwEFipyrO9HtgOLBnwxpikFw29EeASMA141AHaHisv5PdHjv4B3SXpftHbDVwDphf5YdnOC5eB28BRSe/bRMH2TuAWMKPIn5d0OMCXwBxghaSfLWHbgHvArCJ/UdKB7AP8DlyRtL8lbAPwEJhd5K8CI5I6YQowm3OSjrQEvgWWF9m4PCxpoupOAtreDBwqSclD9bsu6a7ttcDzGALcBPaW7M6U9KXHQturgD1FKEr1G5f02PYNYCFwRtKzkukTwElgvqTPU3LZdiyZlDjb/YG2FwBrimXdIQ3oqaRfzYtBwGT6Qp/kBLha0qs+wHmJY4/LtlOoiV13t0w0s9lovery34FtSqfLwiQk0F6g7aFy2SyZ7FNn3WdnJd2xXYFzJX2NywnyaPrQ9jJgR6NcMkGa5ZN9PUspvbF9Cjie9pX0LcAPwGtJmWlTXrbHgU2SFtXCzuhKYw9JSsO3Xra3Ag+AdNK+Clxc2inuPgHeDZiHHT0g8huBeLhO0sd6kQG7FDjdmNh/Jnkfc5OgT8W6YxUW2d/z0P8Aqj1uxQAAAABJRU5ErkJggg==',
                    name: 'Solvency index (SI)',
                    text: '210',
                    class: '#1290C9FF',
                },
            ];
        },

        clossuoDialog(value) {
            this.suoclose = value;
        },
        closeDialog() {
            const okButton = document.getElementById('ok');

            okButton.removeEventListener('click', this.onSubmitkoudian);
            this.loginStatus1[this.indexclose] = false;
            this.parentTablelist[this.indexclose].loading2 = false;
            this.$set(this.parentTablelist, this.indexclose, this.parentTablelist[this.indexclose]);
            this.newDialog = false;
        },
        // 提示扣点
        onSubmitkoudian() {
            let that = this;
            let id = this.Obj.id;
            let nameEn = this.Obj.name;
            this.getOrdersAdd(1, id, nameEn).then(res => {
                if (res) {
                    let params = this.params;
                    getPerformance(params).then(res => {
                        let data = JSON.parse(crypto.decrypt(res));
                        let index = that.Obj.index;
                        if (data.code === true) {
                            that.parentTablelist[index].loading2 = false;
                            console.log(data);
                            that.parentTablelist[index].cardlist2.map(item => {
                                if (item.name == 'Revenue') {
                                    item.text = data.data.revenue;
                                }
                                if (item.name == 'Profit') {
                                    item.text = data.data.profit;
                                }
                                if (item.name == 'Growth') {
                                    item.text = data.data.growth;
                                }
                                if (item.name == 'Financial Structure') {
                                    item.text = data.data.structure;
                                }
                                if (item.name == 'Credit Opinion') {
                                    item.text = data.data.creditOpinion;
                                }
                                if (item.name == 'Liquidity') {
                                    item.text = data.data.liquidity;
                                }
                                if (item.name == 'Risk Indicator') {
                                    item.text = data.data.riskIndicator;
                                }
                                if (item.name == 'Solvency index (SI)') {
                                    console.log(data.data);
                                    item.text = data.data.si;
                                }
                            });
                            that.loginStatus1[index] = true;
                            that.$set(that.parentTablelist, index, that.parentTablelist[index]);
                        } else {
                            that.parentTablelist[index].loading2 = false;
                        }
                    });
                } else {
                    let index = that.Obj.index;
                    that.loginStatus1[index] = false;
                    that.parentTablelist[index].loading2 = false;
                    that.$set(this.parentTablelist, index, that.parentTablelist[index]);
                }
            });
            this.newDialog = false;
        },
        // 取消
        onSubmitquxiao() {
            // this.newDialog = false;
            // this.$emit('closeCloseDialog', false);
            let that = this;
            let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;

            console.log(`userinfo`, userinfo);
            let routeData = '';
            setTimeout(function () {
                if (userinfo.type == '3') {
                    routeData = that.$router.resolve({
                        path: '/account/quota',
                    });
                } else {
                    routeData = that.$router.resolve({
                        path: '/account/addCredit',
                    });
                }

                window.open(routeData.href, '_blank');
            }, 1000);
        },
        getlist() {
            const params = {
                id3a: this.$route.query.id3a,
                companyCountry: this.$route.query.companyCountry,
                // id3a: JSON.parse(sessionStorage.getItem("company")).aaa_id,
                // companyCountry: JSON.parse(sessionStorage.getItem("company")).company_country,
            };
            this.getcardlist(params);

            // console.log(this.cardlist);
        },
        onPageChangeList() {},
        tab(item) {},
        handleChange(val) {
            this.icon = val;
        },
        infiniteScroll() {
            this.routeLoad = false;
        },
        getrelateList() {
            getrelatedCompanylist({ id3a: this.$route.query.id3a, page: this.page.pageNo, pageSize: this.page.pageSize, resourceType: 1 }).then(res => {
                if (res && res.code) {
                    let list = res.data.rows;
                    this.parentTablelist = res.data.rows;
                    this.routeLoad = false;
                    this.parentTablelist.map(item => {
                        item.loading2 = false;
                        item.cardlist2 = [
                            { icon: require('@/assets/img/company/rev.png'), name: 'Revenue', text: '', class: '#1290C9FF' },
                            { icon: require('@/assets/img/company/pro.png'), name: 'Profit', text: '', class: '#55B419FF' },
                            { icon: require('@/assets/img/company/gro.png'), name: 'Growth', text: '', class: '#022955FF' },
                            { icon: require('@/assets/img/company/LIqui.png'), name: 'Liquidity', text: '', class: '#F56B6BFF' },
                            { icon: require('@/assets/img/company/finan.png'), name: 'Financial Structure', text: '', class: '#01DAC3FF' },
                            { icon: require('@/assets/img/company/crea.png'), name: 'Credit Opinion', text: '', class: '#6668D1FF' },
                            { icon: require('@/assets/img/company/risk.png'), name: 'Risk Indicator', text: '', class: '#FF7600FF' },
                            { icon: require('@/assets/img/company/crea.png'), name: 'Solvency index (SI)', text: '', class: '#1290C9FF' },
                        ];
                        // item.loading1=fal
                    });
                    // console.log(`this.parentTablelist`, this.parentTablelist);
                }
            });
        },
        async loginPop(index, id, nameEn) {
            this.indexclose = index;
            this.$set(this.loginStatus1, index, !this.loginStatus1[index]);

            if (this.loginStatus1[index]) {
                this.parentTablelist[index].loading2 = true;
                console.log(` this.parentTablelist[index]`, this.parentTablelist[index].loading2);
                const params = {
                    id3a: id,
                    // companyCountry: 'US',
                    // id3a: JSON.parse(sessionStorage.getItem("company")).aaa_id,
                    // companyCountry: JSON.parse(sessionStorage.getItem("company")).company_country,
                    // companyCountry: JSON.parse(sessionStorage.getItem("comany")).company_country,
                };

                let res = await getCompanyDetailMenuList('code=' + id + '&type=' + 7);
                let power = res.data[5].power;
                if (power) {
                    getPerformance(params).then(res => {
                        let data = JSON.parse(crypto.decrypt(res));

                        if (data.code === true) {
                            this.parentTablelist[index].loading2 = false;

                            this.parentTablelist[index].cardlist2.map(item => {
                                if (item.name == 'Revenue') {
                                    item.text = data.data.revenue;
                                }
                                if (item.name == 'Profit') {
                                    item.text = data.data.profit;
                                }
                                if (item.name == 'Growth') {
                                    item.text = data.data.growth;
                                }
                                if (item.name == 'Financial Structure') {
                                    item.text = data.data.structure;
                                }
                                if (item.name == 'Credit Opinion') {
                                    item.text = data.data.creditOpinion;
                                }
                                if (item.name == 'Liquidity') {
                                    item.text = data.data.liquidity;
                                }
                                if (item.name == 'Risk Indicator') {
                                    item.text = data.data.riskIndicator;
                                }
                                if (item.name == 'Solvency index (SI)') {
                                    console.log(data.data);
                                    item.text = data.data.si;
                                }
                            });

                            // this.parentTablelist[index].cardlist2 = this.list;
                            this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                            // console.log(this.parentTablelist[index]);

                            // resolve(data.data)
                        } else {
                            // Message({
                            //   message: '账户或密码错误！',
                            //   type: 'warning'
                            // })
                            // return Promise.reject('error')
                        }
                    });
                } else {
                    this.newDialog = true;
                    this.params = params;
                    this.Obj.name = nameEn;
                    this.Obj.id = id;
                    this.Obj.index = index;
                    // 不需要提示处理
                    // this.getOrdersAdd(1, id, nameEn).then(res => {
                    //     if (res) {
                    //         getPerformance(params).then(res => {
                    //             let data = JSON.parse(crypto.decrypt(res));

                    //             if (data.code === true) {
                    //                 this.parentTablelist[index].loading2 = false;
                    //                 console.log(data);
                    //                 this.parentTablelist[index].cardlist2.map(item => {
                    //             if (item.name == 'Revenue') {
                    //                 item.text = data.data.revenue;
                    //             }
                    //             if (item.name == 'Profit') {
                    //                 item.text = data.data.profit;
                    //             }
                    //             if (item.name == 'Growth') {
                    //                 item.text = data.data.growth;
                    //             }
                    //             if (item.name == 'Financial Structure') {
                    //                 item.text = data.data.structure;
                    //             }
                    //             if (item.name == 'Credit Opinion') {
                    //                 item.text = data.data.creditOpinion;
                    //             }
                    //             if (item.name == 'Liquidity') {
                    //                 item.text = data.data.liquidity;
                    //             }
                    //             if (item.name == 'Risk Indicator') {
                    //                 item.text = data.data.riskIndicator;
                    //             }
                    //             if (item.name == 'Solvency index (SI)') {
                    //                 console.log(data.data);
                    //                 item.text = data.data.si;
                    //             }
                    //         });
                    //         this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                    //             } else {
                    //                this.parentTablelist[index].loading2 = false;
                    //             }
                    //         });
                    //     }else{
                    //         this.loginStatus1[index] = false;
                    //         this.parentTablelist[index].loading2 = false;
                    //         this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                    //     }
                    // });
                }
            }
        },
        async getOrdersAdd(type, aid, nameEn) {
            const id3a = aid;

            //1  :线上报告
            let params = {
                companyName: nameEn, //必须
                aaaId: id3a, //必须
                // companyCountry: companyCountry, //必须
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            let status = '';

            await ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code) {
                    //  getCompanyDetailMenuList('code=' + aid + '&type=' + 7).then(res=>{
                    //  console.log(res);
                    //  });
                    status = true;
                    if (data.msg != 'Saved successfully') {
                        this.$message({
                            message: data.msg,
                            type: 'success',
                            // duration: 5000,
                        });
                    }
                } else {
                    this.$message({
                        message: data.msg,
                        type: 'error',
                        // duration: 5000,
                    });
                    let that = this;
                    let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;

                    let routeData = '';
                    setTimeout(function () {
                        if (userinfo.type == '3') {
                            routeData = that.$router.resolve({
                                path: '/account/quota',
                            });
                        } else {
                            routeData = that.$router.resolve({
                                path: '/account/addCredit',
                            });
                        }

                        window.open(routeData.href, '_blank');
                    }, 2000);

                    status = false;
                }
            });
            this.getliulantime(params, 'Performance');
            return status;
        },
        setid(item) {
            console.log(item);
            // sessionStorage.setItem('company', JSON.stringify(row));
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + item.aaaid + '&companyCountry=' + '',
            });

            window.open(routeData.href, '_blank');
        },
        handleScroll() {
            // 判断页面滚动距离是否超过200px，更新showBackToTop的值
            // console.log(` this.showBackToTop`, this.showBackToTop );
            this.showBackToTop = window.pageYOffset > 1000;
        },
        scrollToTop() {
            // 使用scrollTo()方法将页面滚动到顶部
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>
<style scoped lang="less">
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}

.Company Snapshot {
}

.Country Data {
}

.title {
    display: flex;
    align-items: center;

    justify-content: space-between;
    // margin-top: 69px;

    .title-left {
        display: flex;
        font-size: 16px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #022955;
    }
}

.table {
    margin-top: 20px;
}

.el-card.is-always-shadow {
    box-shadow: none;
}
.btn_blue {
    position: absolute;
    top: 65px;
    right: 0;
    width: 140px;
    height: 40px;
    line-height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
}
// 卡片
/deep/ .el-card {
    // border: 1px solid #EBEEF5;
    background-color: #fff;
    color: #303133;
    transition: 0.3s;
    border: none;

    .el-card__body {
        padding: 3px;
        display: flex;
        flex-wrap: wrap;

        .item {
            display: flex;
            padding: 15px 35px 15px 20px;

            height: 70px;
            background: #ffffff;
            box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
            border-radius: 14px;
            margin: 0 20px 20px 0;

            .card-text {
                margin-left: 10px;
                display: flex;
                width: 110px;
                flex-wrap: wrap;

                .text-head {
                    width: 110px;
                    height: 16px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #8497ab;
                }

                .text-foot {
                    width: 110px;
                    height: 18px;
                    font-size: 12px;
                    font-family: Arial-BoldMT, Arial;
                    font-weight: normal;
                    color: #022955;
                    margin-top: 8px;
                }
            }
        }
    }
}
.footer {
    margin-top: 20px;
    padding: 20px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}

.ecahrts-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-left {
        display: flex;
    }
}

.top_tab {
    margin-top: 16px;
}

.tab_box /deep/ .el-tabs__header {
    margin-bottom: 40px;
}

.el-tabs--card > .el-tabs__header {
    border: none;
}

.tab_box /deep/ .el-tabs__header,
.tab_box /deep/.el-tabs__content {
    width: 100%;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}

.tab_box /deep/ .el-tabs__header {
    padding: 11px 40px;
}

.result_tab {
    padding: 20px;
}

.tab_box /deep/ .el-tabs__nav-wrap {
    width: 100%;
    height: 34px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}

.tab_box /deep/ .el-tabs__nav {
    width: 100%;
    border: none;
    padding: 4px 15px;
    display: flex;
    justify-content: space-around;
}

.tab_box /deep/ .el-tabs__item {
    padding: 0 15px;
    width: 156px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    color: #8497ab;
    border: none;
    text-align: center;
}

.tab_box /deep/ .el-tabs__item:last-child {
    padding-right: 15px !important;
}

.tab_box /deep/ .el-tabs__item.is-active {
    background: #ff7600;
    box-shadow: 0px 1px 2px 0px #c44306;
    border-radius: 4px;
    font-family: 'Arial Bold';
    color: #ffffff;
}
/deep/ .table-biao {
    height: auto;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 14px;
    border: 1px solid #e7eaed;
}
.infinite-list-wrapper {
    // height: 500px;
    padding: 4px;
}
.line_1 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.back-to-top {
    z-index: 999;
    position: fixed;
    transition: opacity 0.4s ease-in-out 0s;
    opacity: 1;
    box-sizing: border-box;
    bottom: 86px;
    right: 45px;
    cursor: pointer;
    /* background-color: #1290c9; */
}

.gaodu {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    background-color: #1290c9;
    color: #fff;
    font-size: 20px;
}
.container {
    margin-top: 69px;
}
.detail_cont {
    padding: 0px 10px 10px;
}
.detail_cont_text {
    font-size: 14px;
    color: #022955;
}
@media (max-width: 821px) {
    .container {
        margin-top: 20px !important;
    }
    /deep/ .el-card .el-card__body {
        justify-content: space-between;
    }
    /deep/ .el-card .el-card__body .item {
        width: 100%;
        padding: 10px 2px !important;
        margin: 0px !important;
        /* border: 1px; */
        border-bottom: 1px solid #e0dce5;
        border-radius: 0px !important;
        box-shadow: none !important;
        height: auto !important;
    }
    /deep/ .el-card .el-card__body .item .card-text {
        margin-left: 0px !important;
        display: flex;
        /* width: 110px; */
        flex-wrap: nowrap !important;
        width: 100% !important;
        justify-content: space-between;
        align-items: center;

        text-align: right !important;
    }
    /deep/ .el-card .el-card__body .item .card-text .text-head {
        width: 150px !important;
        text-align: left;
        color: #022955 !important;
        font-size: 14px !important;
    }

    .footer {
        padding: 10px !important;
    }
    .title-left {
        color: #1290c9;
    }
    .phone_flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .phone_flex .btn_export {
        width: 280px;
        border-radius: 30px;
        margin: 20px;
    }
}
</style>